import camelcase from 'lodash.camelcase';

// Register all modules inside the modules folder.
// See https://www.youtube.com/watch?v=7lpemgMhi0k for more details.
const requireModule = require.context('.', false, /\.js$/);
const modules = {};

requireModule.keys().forEach(fileName => {
  // Don't register this file.
  if (fileName === './index.js') return;

  const moduleName = camelcase(fileName.replace(/(\.\/|\.js)/g, ''));
  modules[moduleName] = {
    namespaced: true,
    ...requireModule(fileName)
  };
});

export default modules;
