export const ACCOUNT_LEVEL = 'Account';
export const COST_CENTRE_LEVEL = 'CostCentre';

export const REWARD_LEVEL_LABELS = {
  [ACCOUNT_LEVEL]: 'Account level',
  [COST_CENTRE_LEVEL]: 'Cost centre level'
};

export const ACCOUNT_NUMBER = 'accountNumber';

export const AIRPOINTS = 'Airpoints';

export const BRAND = 'Z Business';
