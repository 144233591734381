export const state = () => ({
  accumulationLevel: null,
  hasCostCentres: null,
  sectionComplete: false,
  invalid: true
});

export const mutations = {
  setAccumulationLevel(state, value) {
    state.accumulationLevel = value;
  },
  setHasCostCentres(state, value) {
    state.hasCostCentres = value;
  },
  changeSectionComplete(state, value) {
    state.sectionComplete = value;
  },
  changeInvalid(state, value) {
    state.invalid = value;
  }
};

export const actions = {
  setAccumulationLevel({ commit }, value) {
    commit('setAccumulationLevel', value);
  },
  setHasCostCentres({ commit }, value) {
    commit('setHasCostCentres', value);
  },
  changeSectionComplete({ commit }, value) {
    commit('changeSectionComplete', value);
  },
  changeInvalid({ commit }, value) {
    commit('changeInvalid', value);
  }
};

export const getters = {};
