import { render, staticRenderFns } from "./ExpansionPanel.vue?vue&type=template&id=5ee5a0fb&scoped=true&"
import script from "./ExpansionPanel.vue?vue&type=script&lang=js&"
export * from "./ExpansionPanel.vue?vue&type=script&lang=js&"
import style0 from "./ExpansionPanel.vue?vue&type=style&index=0&id=5ee5a0fb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ee5a0fb",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VExpansionPanel } from 'vuetify/lib'
installComponents(component, {
  VExpansionPanel,
})
