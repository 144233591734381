export const state = () => ({
  hasAuthority: null,
  hasReadTermsAndConditions: null,
  sectionComplete: null
});

export const mutations = {
  changeHasAuthority(state, value) {
    state.hasAuthority = value;
  },
  changeHasReadTermsAndConditions(state, value) {
    state.hasReadTermsAndConditions = value;
  },
  changeSectionComplete(state, value) {
    state.sectionComplete = value;
  }
};

export const actions = {
  changeHasAuthority({ commit }, value) {
    commit('changeHasAuthority', value);
  },
  changeHasReadTermsAndConditions({ commit }, value) {
    commit('changeHasReadTermsAndConditions', value);
  },
  changeSectionComplete({ commit }, value) {
    commit('changeSectionComplete', value);
  }
};

export const getters = {};
