export const state = () => ({
  name: '',
  email: '',
  sectionComplete: false
});

export const mutations = {
  setName(state, value) {
    state.name = value;
  },
  setEmail(state, value) {
    state.email = value;
  },
  changeSectionComplete(state, value) {
    state.sectionComplete = value;
  },
};

export const actions = {
  setName({ commit }, value) {
    commit('setName', value);
  },
  setEmail({ commit }, value) {
    commit('setEmail', value);
  },
  changeSectionComplete({ commit }, value) {
    commit('changeSectionComplete', value);
  }
};

export const getters = {
  firstName: state => {
    const name = state.name;

    if (!name.length) {
      return name;
    }

    const parts = name.split(' ');

    return parts[0];
  }
};
