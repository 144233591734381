import Vue from 'vue';
import Router from 'vue-router';
import { LANDING, FORM, COMPLETED } from './constants/routes';
import { BRAND } from '@/constants/form';
import store from './store';

Vue.use(Router);

const scrollBehavior = function (to) {
  return new Promise(resolve => {
    if (to.hash) {
      setTimeout(() => {
        resolve({ selector: to.hash });
      }, 500);
    } else {
      resolve({ x: 0, y: 0 });
    }
  });
};

const routes = [
  {
    name: 'landing',
    path: LANDING,
    meta: {
      title: `${BRAND} Rewards Association Form`
    },
    component: () =>
      import(/* webpackChunkName: "pages_index" */ './views/index.vue').then(
        m => m.default || m
      )
  },
  {
    name: 'form',
    path: FORM,
    component: () =>
      import(/* webpackChunkName: "pages_form" */ './views/form/index.vue').then(
        m => m.default || m
      ),
    meta: {
      layout: 'form'
    },
    beforeEnter: (to, from, next) => {
      // Prevent users from navigating back to the form from the completed page.
      if (from.path == COMPLETED) {
        next(false);
        return;
      }
      // prevent accessing form if havent completed the landing page details
      if (store.state.landing.sectionComplete !== true) {
        next(LANDING);
      }
      next();
    }
  },
  {
    name: 'completed',
    meta: {
      completed: true
    },
    path: COMPLETED,
    component: () =>
      import(/* webpackChunkName: "pages_completed" */ './views/completed.vue').then(
        m => m.default || m
      ),
    beforeEnter: (to, from, next) => {
      // only accessible when terms and conditions section complete
      if (store.state.termsAndConditions.sectionComplete === true) {
        next();
      } else {
        next(LANDING);
      }
    }
  },
  {
    path: '*',
    component: () =>
      import(/* webpackChunkName: "pages_notfound" */ './views/not-found.vue').then(
        m => m.default || m
      )
  }
];

let router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,
  routes
});

// Show loading bar when navigating between pages
router.beforeEach((to, from, next) => {
  // Prevent users from navigating back to see details about the completed form.
  if (from.meta.completed) {
    // Use window.location.reload().location.href to ensure any state data
    // is cleared. This should result in the user being sent back to the beginning of the form.
    window.location.reload();
    return;
  }

  store.dispatch('ui/changeIsLoading', true);

  // Override the document title if present in the route meta data
  if (to.meta.title) {
    document.title = to.meta.title;
  }

  // Loading bar doesn't work properly without this
  setTimeout(() => {
    next();
  }, 1);
});

router.afterEach(() => {
  store.dispatch('ui/changeIsLoading', false);
});

export default router;
