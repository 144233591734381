// Form sections
export const SECTION_ASSOCIATION_LEVEL = 'association-level';
export const SECTION_ACCOUNT_NUMBER = 'account-number';
export const SECTION_LOYALTY_DETAILS = 'loyalty-details';
export const SECTION_TERMS_AND_CONDITIONS = 'terms-and-conditions';

// Form fields
export const FIELD_LANDING = 'landing-fields';

export const FIELD_ASSOCIATION_REWARD_LEVEL = 'reward-level';
export const FIELD_ASSOCIATION_COST_CENTRES = 'cost-centres';

export const FIELD_ACCOUNT_NUMBER = 'account-number-field';

export const FIELD_LOYALTY_TYPE = 'loyalty-type';
export const FIELD_LOYALTY_TYPE_AIRPOINTS = 'airpoints';

export const FIELD_TERMS_AND_CONDITIONS = 'terms-and-conditions-field';

// How to identify fields with an error
export const FIELD_ERROR_SELECTOR = 'div.error--text';
