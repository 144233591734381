export const state = () => ({
  toasts: [],
  isLoading: false,
  // Main form
  drawerOpen: false,
  openDrawerPanelIndex: 0,
  mobileMenuOpen: false,
  noCostCentresDialogOpen: false,
  invalidAccumulationLevelDialogOpen: false,
  removeLoyaltyDetailsIndex: null,
  removeLoyaltyDetailsDialogOpen: false
});

export const getters = {
  isLoading: state => state.isLoading
};

export const mutations = {
  addToast(state, { id, text, type, canDismiss }) {
    state.toasts = [...state.toasts, { id, text, type, canDismiss }];
  },
  removeToastByIndex(state, indexToRemove) {
    state.toasts = state.toasts.filter((toast, index) => {
      return indexToRemove !== index;
    });
  },
  removeToastById(state, idToRemove) {
    state.toasts = state.toasts.filter(toast => {
      return idToRemove !== toast.id;
    });
  },
  toggleDrawer(state, isOpen) {
    state.drawerOpen = isOpen;
  },
  changeIsLoading(state, value) {
    state.isLoading = value;
  },
  changeDrawerPanelIndex(state, index) {
    state.openDrawerPanelIndex = index;
  },
  toggleMobileMenuOpen(state, isOpen) {
    state.mobileMenuOpen = isOpen;
  },
  toggleNoCostCentresDialogOpen(state, isOpen) {
    state.noCostCentresDialogOpen = isOpen;
  },
  toggleInvalidAccumulationLevelDialogOpen(state, isOpen) {
    state.invalidAccumulationLevelDialogOpen = isOpen;
  },
  toggleRemoveLoyaltyDetailsDialogOpen(state, { isOpen, index }) {
    state.removeLoyaltyDetailsIndex = index;
    state.removeLoyaltyDetailsDialogOpen = isOpen;
  }
};

export const actions = {
  addErrorToast({ commit }, { id, text, error }) {
    // If a validate error from the API has been passed in...
    if (
      error &&
      error.response &&
      error.response.status === 400 &&
      error.response.data &&
      error.response.data.exception
    ) {
      const exception = error.response.data.exception;
      // Check if the error message is an array or single string
      if (Array.isArray(exception)) {
        for (let i = 0; i < exception.length; i++) {
          // Put a slight delay between each toast
          setTimeout(() => {
            commit('addToast', {
              id: `${id}${i}`,
              text: exception[i].errorMessage,
              type: 'error'
            });
          }, i * 500);
        }
        return;
      }

      // If it's not an array, then it should be some text we can display.
      text = exception;
    }

    commit('addToast', { id, text, type: 'error' });
  },
  /**  @description Similar to a toast but must be dismissed by the user. */
  addAlert({ commit }, { id, text }) {
    commit('addToast', { id, text, type: 'error', canDismiss: true });
  },
  addToast({ commit }, { id, text }) {
    commit('addToast', { id, text, canDismiss: false });
  },
  removeToastByIndex({ commit }, index) {
    commit('removeToastByIndex', index);
  },
  removeToastById({ commit }, id) {
    commit('removeToastById', id);
  },
  toggleDrawer({ commit }, isOpen) {
    commit('toggleDrawer', isOpen);
  },
  changeIsLoading({ commit }, value) {
    commit('changeIsLoading', value);
  },
  changeDrawerPanelIndex({ commit }, index) {
    commit('changeDrawerPanelIndex', index);
  },
  toggleMobileMenuOpen({ commit }, isOpen) {
    commit('toggleMobileMenuOpen', isOpen);
  },
  toggleNoCostCentresDialogOpen({ commit }, isOpen) {
    commit('toggleNoCostCentresDialogOpen', isOpen);
  },
  toggleInvalidAccumulationLevelDialogOpen({ commit }, isOpen) {
    commit('toggleInvalidAccumulationLevelDialogOpen', isOpen);
  },
  toggleRemoveLoyaltyDetailsDialogOpen({ commit }, { isOpen, index }) {
    commit('toggleRemoveLoyaltyDetailsDialogOpen', { isOpen, index });
  }
};
