// NOTE: CSS order matters!!!
import 'normalize-scss/sass/normalize/_import-now.scss';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import 'vuetify/src/stylus/app.styl'; // import 'vuetify/dist/vuetify.min.css';
import '@/assets/styles/base.scss';

import '@babel/polyfill'; // Needed for IE
import 'intersection-observer'; // Needed for IE and Edge

import Vue from 'vue';
import store from './store'; // Must be imported before router
import router from './router';
import { sync } from 'vuex-router-sync';
import App from './App.vue';
import { setClientViewport } from '@/helpers/mobile';

import analytics from './plugins/vue-analytics';
import './plugins/error-handler';
import './plugins/vuetify';
import './plugins/vuelidate';
import './plugins/vue-observe-visibility';

sync(store, router);

Vue.use(analytics, { router });

new Vue({
  router,
  store,
  render: h => h(App),
  mounted: () => {
    document.dispatchEvent(new Event('x-app-rendered'));
    // Don't override the viewport when pre-rendering
    if (!window.__PRERENDER_INJECTED) {
      setClientViewport();
    }
  }
}).$mount('#app-container');
