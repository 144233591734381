export const LOAD_DRAFT_ERROR =
  'There was a problem trying to load your draft application. Please try again later or restart the application.';
export const SAVE_DRAFT_ERROR =
  'Sorry, we are unable to save a draft at this time. Please try again later.';
export const SHARE_DRAFT_ERROR =
  'Sorry, the save link could not be shared. Please try again later.';
export const SUBMIT_PG_ERROR =
  'Sorry, your personal guarantee could not be submitted. Please try again later.';
export const SAVE_CARDS_ERROR =
  'Sorry, we are unable to save card details at this time. Please contact us on 0800 Z ENERGY (0800 936 3749)';
export const SAVE_APPLICATION_ERROR =
  'Sorry, we are unable to save application at this time. Please try again later or contact us on 0800 Z ENERGY (0800 936 3749)';
export const LOAD_OFFER_ERROR =
  'Sorry, we are unable to save application at this time. Please try again later or contact us on 0800 Z ENERGY (0800 936 3749)';
