import Vue from 'vue';
import { JL } from 'jsnlog';

// Global error handler for vue errors
Vue.config.errorHandler = function (err) {
  // `info` is a Vue-specific error info, e.g. which lifecycle hook the error was found in.
  console.log(err);
  JL().fatalException('Uncaught Exception', err);
};

// Error handler for http requests.
// Vue.prototype.$http.interceptors.response.use(
//     response => {
//         return response;
//     },
//     error => {
//         console.log("axios response interceptor");
//         console.log(error);
//         return Promise.reject(error);
//     });

// Error handler for any remaining uncaught errors
window.onerror = (message, source, lineno, colno, error) => {
  console.log(error);
  JL().fatalException('Uncaught Exception', error);
};
