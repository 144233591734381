<template>
  <v-dialog
    v-model="mobileMenuOpen"
    fullscreen
    content-class="mobile-menu-content"
  >
    <div class="mobile-menu-inner">
      <form-navigation
        class="form-navigation"
        direction="column"
      />
      <icon-button
        class="mobile-close-button"
        @click="onClose"
      >
        <close-menu-icon />
      </icon-button>
    </div>
  </v-dialog>
</template>

<script>
import IconButton from '@/components/atoms/IconButton.vue';
import FormNavigation from '@/components/molecules/FormNavigation.vue';
import CloseMenuIcon from '@/assets/icons/mobile-menu-close.svg';
import TextButton from '@/components/atoms/TextButton.vue';

export default {
  components: {
    IconButton,
    FormNavigation,
    CloseMenuIcon,
    TextButton
  },
  computed: {
    mobileMenuOpen: {
      get() {
        return this.$store.state.ui.mobileMenuOpen;
      },
      set(value) {
        this.$store.dispatch('ui/toggleMobileMenuOpen', value);
      }
    }
  },
  methods: {
    onClose() {
      this.$store.dispatch('ui/toggleMobileMenuOpen', false);
    }
  }
};
</script>

<style lang="scss">
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.mobile-menu-content {
  background-color: $white;
}

.mobile-menu-inner {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: rem(40px 0);
}

.save-btn {
  margin-bottom: rem(50px);
}

.form-navigation {
  margin-bottom: rem(70px);
}
</style>
