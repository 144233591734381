import axios from 'axios';
import {
  API_BASE_URL,
  API_GATEWAY_URL,
  API_GATEWAY_SUBSCRIPTION_KEY,
} from '@/helpers/config';

const http = axios.create({
  baseURL: API_BASE_URL
});

const GATEWAY_AUTH_HEADERS = {
  's-id': API_GATEWAY_SUBSCRIPTION_KEY
};

export default {
  post: (url, data, config) => http.post(url, data, config),

  submitForm: data => http.post(`/v1/customerrequest/association`, data),

  validateAirpoints: (airpointsNumber, airpointsFirstName, airpointsLastName) =>
    http({
      method: 'post',
      url: `${API_GATEWAY_URL}/v1/airpoints/validatenumber`,
      headers: {
        ...GATEWAY_AUTH_HEADERS
      },
      data: {
        airpointsNumber,
        airpointsFirstName,
        airpointsLastName
      }
    })
};
