<template>
  <button
    :class="[
      'text-button',
      `text-button--${color}`,
      {'text-button--active': active},
      {'text-button--disabled': disabled},
      {'text-button--always-underline': alwaysUnderline}
    ]"
    :active="active"
    :disabled="disabled"
    type="button"
    @click="onClick"
    :name="name"
  >
    <slot />
  </button>
</template>

<script>
export default {
  components: {},
  props: {
    color: {
      type: String,
      default: 'orange',
      validators: value => {
        return ['orange', 'dark-blue'].includes(value);
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    },
    alwaysUnderline: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: null
    }
  },
  methods: {
    onClick() {
      this.$emit('click');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.text-button {
  position: relative;
  font-size: rem(18px);
  font-weight: $weight-black;
  outline: 0;
  line-height: normal;
  white-space: nowrap;
  transition: all 0.3s ease;

  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 2px;
    border-radius: 3px;
    background-color: currentColor;
    opacity: 0;
    visibility: hidden;
  }

  &--orange {
    color: $mid-orange;

    &.text-button--active,
    &:hover {
      color: $orange;
    }
  }

  &--dark-blue {
    color: $dark-blue;
  }

  &--active,
  &--always-underline,
  &:hover {
    &:after {
      opacity: 1;
      visibility: visible;
    }
  }

  &--disabled {
    pointer-events: none;
    color: $light-charcoal;
  }
}
</style>
