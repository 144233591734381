export const state = () => ({
  accountNumber: null,
  sectionComplete: false,
  invalid: true
});

export const mutations = {
  setAccountNumber(state, value) {
    state.accountNumber = value;
  },
  changeSectionComplete(state, value) {
    state.sectionComplete = value;
  },
  changeInvalid(state, value) {
    state.invalid = value;
  }
};

export const actions = {
  setAccountNumber({ commit }, value) {
    commit('setAccountNumber', value);
  },
  changeSectionComplete({ commit }, value) {
    commit('changeSectionComplete', value);
  },
  changeInvalid({ commit }, value) {
    commit('changeInvalid', value);
  }
};

export const getters = {};
