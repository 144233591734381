<template>
  <v-snackbar
    :value="value"
    :timeout="canDismiss ? 0 : 7500"
    :class="['toast', `toast--${type}`]"
    bottom
    left
    auto-height
    @input="onInput"
  >
    <typography type="small-p" no-margin>
      <slot />
    </typography>
     <v-btn v-if="canDismiss" dark flat @click="onInput(false)">Close</v-btn>
  </v-snackbar>
</template>

<script>
import Typography from '@/components/atoms/Typography.vue';

export default {
  components: {
    Typography
  },
  props: {
    value: {
      type: null,
      default: undefined
    },
    type: {
      type: String,
      default: 'regular',
      validator: value => {
        return ['regular', 'error'].includes(value);
      }
    },
    canDismiss: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onInput(value) {
      this.$emit('input', value);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.toast {
  &--regular {
    /deep/ .v-snack__content {
      background-color: $charcoal;
    }
  }

  &--error {
    /deep/ .v-snack__content {
      background-color: $error-color;
    }
  }

  /deep/ .v-snack__content {
    padding: 14px 20px;
  }
}
</style>
