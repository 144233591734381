import api from '@/api';
import { SAVE_APPLICATION_ERROR } from '@/constants/errors';

const ACCUMULATION_LEVEL_ERROR_CODE = 'ERROR-ACCUMULATIONLEVEL-01'

function isInvalidAccumulationLevel({ response }) {
  const isBadRequestError = response && response.status === 400;
  const data = response && response.data;
  const isAccumulationLevelError =
    data &&
    data.validationFailures &&
    data.validationFailures[0] &&
    data.validationFailures[0].errorCode === ACCUMULATION_LEVEL_ERROR_CODE;

  return isBadRequestError && isAccumulationLevelError;
}

export const state = () => ({});

export const mutations = {};

export const actions = {
  async submitForm({ commit, rootState, dispatch }) {
    try {
      // Update progress
      commit(
        'progress/changeProgress',
        { key: 'submitForm', type: 'request' },
        { root: true }
      );

      // Build payload to send
      const data = {
        fullName: rootState.landing.name,
        emailAddress: rootState.landing.email,
        accumulationLevel: rootState.associationLevel.accumulationLevel,
        // coerce null value into false
        hasCostCentres: Boolean(rootState.associationLevel.hasCostCentres),
        accountNumber: rootState.accountNumber.accountNumber,
        associationDetails: rootState.loyaltyDetails.associationDetails,
        hasAuthority: rootState.termsAndConditions.hasAuthority,
        hasReadTermsAndConditions:
          rootState.termsAndConditions.hasReadTermsAndConditions
      };

      await api.submitForm(data);

      // Update progress
      commit(
        'progress/changeProgress',
        { key: 'submitForm', type: 'success' },
        { root: true }
      );

      return true;
    } catch (error) {
      // Update progress
      commit(
        'progress/changeProgress',
        { key: 'submitForm', type: 'error' },
        { root: true }
      );

      // handle invalid accumulation change
      if (isInvalidAccumulationLevel(error)) {
        dispatch('ui/toggleInvalidAccumulationLevelDialogOpen', true, {
          root: true
        });
        return false;
      }

      dispatch(
        'ui/addErrorToast',
        {
          id: 'failed-submit-application',
          text: SAVE_APPLICATION_ERROR,
          error
        },
        { root: true }
      );
      return false;
    }
  }
};

export const getters = {};
