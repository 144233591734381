import Vue from 'vue';
import Vuetify, {
  VApp,
  VContent,
  VFlex,
  VContainer,
  VLayout
} from 'vuetify/lib';

Vue.use(Vuetify, {
  options: {
    cspNonce: 'dQw4w9WgXcQ'
  },
  components: {
    VApp,
    VContent,
    VFlex,
    VContainer,
    VLayout
  },
  theme: {
    primary: '#4f4f4f',
    accent: '#00b3e6',
    error: '#ff2424'
  }
});
