import VueAnalytics from 'vue-analytics';
import { GA_TRACKING_ID } from '@/helpers/config';

// See https://github.com/MatteoGabriele/vue-analytics
const plugin = {
  install(vue, options) {
    if (GA_TRACKING_ID) {
      vue.use(VueAnalytics, {
        id: GA_TRACKING_ID,
        router: options.router //,
        //autoTracking: {
        //   exception: true,
        //   exceptionLogs: false
        //}
      });
    }
  }
};

export default plugin;
