import {
  SECTION_ASSOCIATION_LEVEL,
  SECTION_ACCOUNT_NUMBER,
  SECTION_LOYALTY_DETAILS,
  SECTION_TERMS_AND_CONDITIONS,
  FIELD_ASSOCIATION_REWARD_LEVEL,
  FIELD_ASSOCIATION_COST_CENTRES,
  FIELD_ACCOUNT_NUMBER,
  FIELD_LOYALTY_TYPE,
  FIELD_TERMS_AND_CONDITIONS
} from '@/constants/html-ids';

import { ACCOUNT_LEVEL } from '@/constants/form';

export function distanceBetweenPoints(x, y) {
  return Math.abs(x - y);
}

export function elementDistanceFromOffset(element, yOffset) {
  const elementData = element.getBoundingClientRect();
  const { top, bottom } = elementData;

  const topDistance = distanceBetweenPoints(window.pageYOffset + top, yOffset);
  const bottomDistance = distanceBetweenPoints(
    window.pageYOffset + bottom,
    yOffset
  );

  return Math.min(topDistance, bottomDistance);
}

export const state = () => ({
  questionsInView: [],
  sectionsInView: [],
  middleOfPage: 0
});

export const mutations = {
  addSectionInView(state, value) {
    if (!state.sectionsInView.includes(value)) {
      state.sectionsInView = [...state.sectionsInView, value];
    }
  },
  removeSectionInView(state, value) {
    if (state.sectionsInView.includes(value)) {
      state.sectionsInView = state.sectionsInView.filter(
        question => question !== value
      );
    }
  },
  removeQuestionInView(state, value) {
    if (state.questionsInView.includes(value)) {
      state.questionsInView = state.questionsInView.filter(
        question => question !== value
      );
    }
  },
  addQuestionInView(state, value) {
    if (!state.questionsInView.includes(value)) {
      state.questionsInView = [...state.questionsInView, value];
    }
  },
  changeMiddleOfPage(state, value) {
    state.middleOfPage = value;
  }
};

export const actions = {
  addSectionInView({ commit, state }, value) {
    if (!state.sectionsInView.includes(value)) {
      commit('addSectionInView', value);
    }
  },
  removeSectionInView({ commit, state }, value) {
    if (state.sectionsInView.includes(value)) {
      commit('removeSectionInView', value);
    }
  },
  addQuestionInView({ commit, state }, value) {
    if (!state.questionsInView.includes(value)) {
      commit('addQuestionInView', value);
    }
  },
  removeQuestionInView({ commit, state }, value) {
    if (state.questionsInView.includes(value)) {
      commit('removeQuestionInView', value);
    }
  },
  changeMiddleOfPage({ commit }, value) {
    commit('changeMiddleOfPage', value);
  }
};

export const getters = {
  /**
   * Returns the id of the current section.
   * Returns the one closest to the middle of the screen if multiple sections are in view
   */
  currentSection: state => {
    const { sectionsInView, middleOfPage } = state;

    // Find the question that is closest to the middle of the screen
    if (!sectionsInView.length) {
      return null;
    }

    if (sectionsInView.length === 1) {
      return sectionsInView[0];
    }

    let closestSectionId;
    let closestDistance;

    for (const sectionId of sectionsInView) {
      const element = document.getElementById(sectionId);

      if (!element) {
        console.error(`Element ${sectionId} not found`);
        continue;
      }

      const elementDistance = elementDistanceFromOffset(element, middleOfPage);

      if (!closestDistance || elementDistance < closestDistance) {
        closestDistance = elementDistance;
        closestSectionId = sectionId;
      }
    }

    return closestSectionId;
  },
  /**
   * Returns the id of the current question
   * Returns the question closest to the middle of the screen if multiple questions are in view
   */
  currentQuestion: state => {
    const { questionsInView, middleOfPage } = state;

    // Find the question that is closest to the middle of the screen
    if (!questionsInView.length) {
      return null;
    }

    if (questionsInView.length === 1) {
      return questionsInView[0];
    }

    let closestQuestionId;
    let closestDistance;

    for (const questionId of questionsInView) {
      const element = document.getElementById(questionId);

      if (!element) {
        console.error(`Element ${questionId} not found`);
        continue;
      }

      const elementDistance = elementDistanceFromOffset(element, middleOfPage);
      if (!closestDistance || elementDistance < closestDistance) {
        closestDistance = elementDistance;
        closestQuestionId = questionId;
      }
    }
    return closestQuestionId;
  },
  /**
   * Returns if the current quesiton has a previous question
   */
  hasPreviousQuestion: (state, getters) => {
    if (getters.currentQuestion === FIELD_ASSOCIATION_REWARD_LEVEL) {
      return false;
    }

    return true;
  },
  /**
   * Returns if the current quesiton has a next question
   */
  hasNextQuestion: (state, getters) => {
    const allQuestions = getters.allQuestions;
    const currentQuestion = getters.currentQuestion;
    const lastQuestionIndex = allQuestions.length - 1;

    // Find index of current question in the all question array
    const currentQuestionIndex = allQuestions.findIndex(item => {
      return item.value === currentQuestion;
    });

    if (currentQuestionIndex < lastQuestionIndex) {
      const nextQuestion = allQuestions[currentQuestionIndex + 1];

      if (nextQuestion.disabled) {
        return false;
      }

      return true;
    }

    return false;
  },
  /**
   * Returns the previous question for the current question
   */
  previousQuestion: (state, getters) => {
    const allQuestions = getters.allQuestions;
    const currentQuestion = getters.currentQuestion;
    const firstQuestionIndex = 0;

    // Find index of current question in the all question array
    const currentQuestionIndex = allQuestions.findIndex(item => {
      return item.value === currentQuestion;
    });

    if (currentQuestionIndex !== firstQuestionIndex) {
      return allQuestions[currentQuestionIndex - 1];
    }

    // Return the first item
    return allQuestions[firstQuestionIndex];
  },
  /**
   * Returns the next question for the current question
   */
  nextQuestion: (state, getters) => {
    const allQuestions = getters.allQuestions;
    const currentQuestion = getters.currentQuestion;
    const lastQuestionIndex = allQuestions.length - 1;

    // Find index of current question in the all question array
    const currentQuestionIndex = allQuestions.findIndex(item => {
      return item.value === currentQuestion;
    });

    if (currentQuestionIndex !== lastQuestionIndex) {
      return allQuestions[currentQuestionIndex + 1];
    }

    // Return the last item
    return allQuestions[lastQuestionIndex];
  },
  /**
   * Returns all the questions in order
   */
  allQuestions: (state, getters) => {
    return [
      ...getters.associationLevelQuestions,
      ...getters.accountNumberQuestions,
      ...getters.loyaltyDetailsQuestions,
      ...getters.termsAndConditionsQuestions
    ];
  },
  /**
   * Returns all of the association level questions in order
   */
  associationLevelQuestions: (state, getters, rootState) => {
    if (
      !rootState.associationLevel.accumulationLevel ||
      rootState.associationLevel.accumulationLevel === ACCOUNT_LEVEL
    ) {
      return [
        {
          value: FIELD_ASSOCIATION_REWARD_LEVEL,
          disabled: false
        }
      ];
    } else {
      return [
        {
          value: FIELD_ASSOCIATION_REWARD_LEVEL,
          disabled: false
        },
        {
          value: FIELD_ASSOCIATION_COST_CENTRES,
          disabled: !rootState.associationLevel.hasCostCentres
        }
      ];
    }
  },

  /**
   * Returns all of the account number questions in order
   */
  accountNumberQuestions: (state, getters, rootState) => {
    return [
      {
        value: FIELD_ACCOUNT_NUMBER,
        disabled: !rootState.associationLevel.sectionComplete
      }
    ];
  },

  /**
   * Returns all of the loyalty details questions in order
   */
  loyaltyDetailsQuestions: (state, getters, rootState) => {
    return [
      {
        value: FIELD_LOYALTY_TYPE,
        disabled: !rootState.accountNumber.sectionComplete
      }
    ];
  },
  /**
   * Returns all of the terms and conditions questions in order
   */
  termsAndConditionsQuestions: (state, getters, rootState) => {
    return [
      {
        value: FIELD_TERMS_AND_CONDITIONS,
        disabled: !rootState.loyaltyDetails.sectionComplete
      }
    ];
  },

  /**
   * Returns all of the questions for the current section
   */
  currentQuestions: (state, getters) => {
    switch (getters.currentSection) {
      case SECTION_ASSOCIATION_LEVEL:
        return getters.associationLevelQuestions;
      case SECTION_ACCOUNT_NUMBER:
        return getters.accountNumberQuestions;
      case SECTION_LOYALTY_DETAILS:
        return getters.loyaltyDetailsQuestions;
      case SECTION_TERMS_AND_CONDITIONS:
        return getters.termsAndConditionsQuestions;
      default:
        break;
    }
  }
};
