<template>
  <nav :class="['navigation', `navigation--${direction}`]">
    <text-button class="nav-btn" @click="onContactDetailsClick">Contact details</text-button>

    <text-button
      :active="currentSection === associationLevelSectionId"
      class="nav-btn"
      @click="onNavItemClick(associationLevelSectionId)"
    >Reward level</text-button>

    <text-button
      :active="currentSection === accountNumberSectionId"
      :disabled="!associationLevelComplete"
      class="nav-btn"
      @click="onNavItemClick(accountNumberSectionId)"
    >Account number</text-button>

    <text-button
      :active="currentSection === loyaltyDetailsSectionId"
      :disabled="!accountNumberComplete"
      class="nav-btn"
      @click="onNavItemClick(loyaltyDetailsSectionId)"
    >Loyalty details</text-button>

    <text-button
      :active="currentSection === termsAndConditionsSectionId"
      :disabled="!loyaltyDetailsComplete"
      class="nav-btn"
      @click="onNavItemClick(termsAndConditionsSectionId)"
    >Terms &amp; conditions</text-button>
  </nav>
</template>

<script>
import TextButton from '@/components/atoms/TextButton.vue';
import {
  SECTION_ASSOCIATION_LEVEL,
  SECTION_ACCOUNT_NUMBER,
  SECTION_LOYALTY_DETAILS,
  SECTION_TERMS_AND_CONDITIONS
} from '@/constants/html-ids';
import { LANDING } from '@/constants/routes';

import { FIXED_HEADER_OFFSET } from '@/constants/ui';
import { mapState, mapGetters } from 'vuex';

export default {
  components: {
    TextButton
  },
  props: {
    direction: {
      type: String,
      default: 'row'
    }
  },
  computed: {
    ...mapState({
      associationLevelComplete: state => state.associationLevel.sectionComplete,
      accountNumberComplete: state => state.accountNumber.sectionComplete,
      loyaltyDetailsComplete: state => state.loyaltyDetails.sectionComplete
    }),
    ...mapGetters({
      currentSection: 'navigation/currentSection'
    }),
    associationLevelSectionId: () => SECTION_ASSOCIATION_LEVEL,
    accountNumberSectionId: () => SECTION_ACCOUNT_NUMBER,
    loyaltyDetailsSectionId: () => SECTION_LOYALTY_DETAILS,
    termsAndConditionsSectionId: () => SECTION_TERMS_AND_CONDITIONS
  },
  methods: {
    onNavItemClick(sectionId) {
      this.$vuetify.goTo(`#${sectionId}`, {
        offset: FIXED_HEADER_OFFSET
      });

      this.$store.dispatch('ui/toggleMobileMenuOpen', false);
    },
    onContactDetailsClick() {
      // allow the user to return to the landing page if they
      // need to edit their name or email
      this.$router.push(LANDING);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.navigation {
  display: flex;
  align-items: center;

  &--row {
    height: 100%;
    flex-direction: row;

    .nav-btn {
      margin-right: rem(40px);
    }
  }

  &--column {
    flex-direction: column;

    .nav-btn {
      margin: rem(15px 0);
    }
  }
}
</style>
