import verge from 'verge';

/**
 *  Set viewport manually so sections with 100vh don't resize when keyboard opens on mobile devices.
 */
export function setClientViewport() {
  let viewport = document.querySelector('meta[name=viewport]');
  const viewportContent = getViewportContent();

  // Create viewport tag if it doesnt exist
  if (viewport === null) {
    const head = document.getElementsByTagName('head')[0];
    viewport = document.createElement('meta');
    viewport.setAttribute('name', 'viewport');
    head.appendChild(viewport);
  }

  viewport.setAttribute('content', viewportContent);
}

export function getViewportContent() {
  const width = verge.viewportW();
  const height = verge.viewportH();

  const viewportContent = `width=${width}, height=${height}, initial-scale=1.0`;

  return viewportContent;
}
